body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.contact-links {
  display: flex;
  gap: 10px;
}

.contact-link {
  cursor: pointer;
}

.home-container {
  background-image: url('/public/images/hero_1.jpg');
  background-size: cover;
  background-position: center;
  height: 100vh;
  display: flex;
  align-items: center;
}

.home-content {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto;
  max-width: 600px;
}

.event {
  background-color: #F2F2F2;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
}

.event-title {
  color: #FF4081;
  font-size: 24px;
  margin-bottom: 10px;
}

.event-info {
  color: #616161;
  font-size: 16px;
  margin-bottom: 5px;
}

.event-description {
  color: #424242;
  font-size: 16px;
}

.about-content {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.contact-info {
  flex: 1;
  margin-right: 30px;
}

.connect-social {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connect-social h2 {
  margin-bottom: 20px;
}

.connect-social .contact-links {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.connect-social .contact-link {
  cursor: pointer;
  font-size: 24px;
  padding: 10px;
  transition: transform 0.2s;
}

.connect-social .contact-link:hover {
  transform: scale(1.2);
}

.about-container {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.about-content {
  margin-top: 20px;
  line-height: 1.6;
}

.about-content p:first-child {
  font-size: 24px;
  font-weight: bold;
}

.about-content p:not(:first-child) {
  margin-bottom: 20px;
}

.about-content p:last-child {
  margin-bottom: 0;
}

.team-container {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1 {
  text-align: center;
  margin-bottom: 30px;
}

.instructors {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  grid-gap: 30px;
  margin-top: 20px;
}

.instructor {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.instructor-image {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 20px;
}

.instructor-details {
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-bottom: 10px;
}

.position {
  color: #888;
  margin-bottom: 10px;
}

.bio {
  line-height: 1.5;
}

.gallery-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.artworks-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.artwork {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.artwork img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.artwork-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 14px;
}

.artwork-details h3 {
  margin: 0;
  font-size: 16px;
}

.artwork-details p {
  margin: 0;
  font-size: 14px;
  opacity: 0.8;
}

.navbar-content {
  overflow: auto;
  padding-right: 20px; /* Add some padding to accommodate the scrollbar */
}

.App {
  position: relative;
  min-height: 100vh;
}

.message-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 9999;
  background-color: #fff;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.chatbot-container {
  position: fixed;
  bottom: 0px; /* Adjust as needed */
  right: 0px;
  z-index: 9999;
  height: auto;
  max-height: 400px;
  width: 320px;
  overflow-y: auto;
}

.chatbot {
  border-radius: 5px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.3s;
}

.chatbot-header {
  background-color: #f2f2f2;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
}

.chatbot-content {
  padding: 10px;
  overflow-y: auto;
  word-wrap: break-word;
  hyphens: auto;
}

.minimized {
  transform: translateY(100%);
}

.minimize-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.resize-handle {
  position: absolute;
  width: 10px;
  height: 10px;
  bottom: 0;
  right: 0;
  background-color: #ddd;
  cursor: nwse-resize;
}
